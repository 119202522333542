<template>
	<div>
		<global-navigation
			:primary-button="{ isVisible: true, label: 'Log in', link: 'https://id.anaplan.com/' }"
			:navigation="getCustomNavigation()"
		/>
		<div class="grid-container--registration">
			<div class="grid-item--form">
				<div class="inline-message">
					<svg-icon icon="validation-info" class="icon icon--info" />
					<p>If you’ve previously logged in to Anaplan Community or Learning Center, contact <a class="link" href="mailto:academy@anaplan.com?subject=Update email address&body=Please include the following information to update your email address: %0D%0A%0D%0A Name: %0D%0A%0D%0A Community username: %0D%0A%0D%0A Old email address: %0D%0A%0D%0A New email address: %0D%0A%0D%0A Company name:  %0D%0A%0D%0A">Anaplan Academy Help Desk</a> to update your email. If not, create an account as described below.</p>
				</div>
				
				<h1 class="t-grapefruit">Create an Anaplan Community account</h1>

				<div class="introWrapper">
					<p>Use your account to:</p>

					<ul>
						<li>Complete training in the Learning Center.</li>
						<li>Connect with other users in Groups and forums.</li>
						<li>Engage in programs and events.</li>
						<li>Create and manage Support tickets.</li>
					</ul>
				</div>

				<div class="grid-container--stepBadges">
					<div>
						<span class="badge">1</span> <h2>Create an account</h2>
					</div>
					<div>
						<span class="badge">2</span> <h2>Activate your account</h2>
						<p>Check your email and create a password.</p>
					</div>
					<div>
						<span class="badge">3</span> <h2>Accelerate your Anaplan experience</h2>
					</div>
				</div>

				<form class="form">
					<fieldset>
						<div class="form__row">
							<label :class="{ 'label': true, 'label--error': $v.userName.$error }" for="userName">
								<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									Community user name
								<div class="tooltip tooltip--right">
									<div class="tooltip__trigger" aria-describedby="tooltip-userName" tabindex="0">
										<svg-icon icon="info" class="icon icon--info"></svg-icon>
									</div>

									<div class="tooltip__content" role="tooltip" id="tooltip-userName">
										Your user name appears when you post in Community
									</div>
								</div>
							</label>
							<input type="text" id="userName" name="userName" placeholder="Community user name" maxlength="256" :class="{ 'input': true, 'input--error': $v.userName.$error }" v-model.trim="$v.userName.$model" />

							<strong class="input__message input__message--error" v-if="$v.userName.$dirty && !$v.userName.required">Enter your Community user name.</strong>
							<strong class="input__message input__message--error" v-if="$v.userName.$dirty && !$v.userName.userNameFormatValidator">Your Community user name must only contain valid characters (A-Z a-z 0-9).</strong>
							<strong class="input__message input__message--error" v-if="$v.userName.$dirty && !$v.userName.isUniqueUserNameValidator">This user name is already connected to a Community account.</strong>
						</div>
					</fieldset>

					<div class="flex-container">
						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label': true, 'label--error': $v.firstName.$error }" for="firstName">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									First name
								</label>

								<input type="text" id="firstName" name="firstName" placeholder="First name" maxlength="256" :class="{ 'input': true, 'input--error': $v.firstName.$error }" v-model.trim="$v.firstName.$model" />

								<strong class="input__message input__message--error" v-if="$v.firstName.$dirty && !$v.firstName.required">Enter your first name.</strong>
							</div>
						</fieldset>

						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label': true, 'label--error': $v.lastName.$error }" for="lastName">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									Last name
								</label>

								<input type="text" id="lastName" name="lastName" placeholder="Last name" maxlength="256" :class="{ 'input': true, 'input--error': $v.lastName.$error }" v-model.trim="$v.lastName.$model" />

								<strong class="input__message input__message--error" v-if="$v.lastName.$dirty && !$v.lastName.required">Enter your last name.</strong>
							</div>
						</fieldset>
					</div>

					<div class="flex-container">
						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label': true, 'label--error': $v.email.$error }" for="email">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
										Email
									<div class="tooltip tooltip--right">
										<div class="tooltip__trigger" aria-describedby="tooltip-email" tabindex="0">
											<svg-icon icon="info" class="icon icon--info"></svg-icon>
										</div>

										<div class="tooltip__content" role="tooltip" id="tooltip-email">
											Create your Community account with the same email you use to access Anaplan
										</div>
									</div>
								</label>

								<input type="email" id="email" name="email" placeholder="Email" maxlength="256" :class="{ 'input': true, 'input--error': $v.email.$error }" v-model.trim="$v.email.$model" />

								<strong class="input__message input__message--error" v-if="$v.email.$dirty && !$v.email.anaplanUserValidator">You are using an Anaplan email. Log in with single sign-on (SSO).</strong>
								<strong class="input__message input__message--error" v-if="$v.email.$dirty && !$v.email.required">Enter your email.</strong>
								<strong class="input__message input__message--error" v-if="$v.email.$dirty && (!$v.email.email || !$v.email.emailExtended)">Your email can include lowercase letters (a-z), numbers (0-9), and @ - _ . characters.</strong>
								<strong class="input__message input__message--error" v-if="$v.email.$dirty && !$v.email.isUniqueEmailValidator">This email is in use.</strong>
							</div>
						</fieldset>

						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label': true, 'label--error': $v.confirmEmail.$error }" for="confirmEmail">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									Confirm email
								</label>

								<input type="email" id="confirmEmail" name="confirmEmail" placeholder="Confirm your email" maxlength="256" :class="{ 'input': true, 'input--error': $v.confirmEmail.$error }" v-model.trim="$v.confirmEmail.$model" />

								<strong class="input__message input__message--error" v-if="$v.confirmEmail.$dirty && !$v.confirmEmail.required">Confirm your email.</strong>
								<strong class="input__message input__message--error" v-if="$v.confirmEmail.$dirty && (!$v.confirmEmail.email || !$v.confirmEmail.emailExtended)">Your email can include lowercase letters (a-z), numbers (0-9), and @ - _ . characters.</strong>
								<strong class="input__message input__message--error" v-if="$v.confirmEmail.$dirty && !$v.confirmEmail.sameAsEmail">The email addresses do not match.</strong>
							</div>
						</fieldset>
					</div>

					<div class="flex-container">
						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label' : true, 'label--error': $v.city.$error }" for="city">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									City
								</label>

								<input type="text" id="city" name="city" placeholder="City" maxlength="256" :class="{ 'input' : true, 'input--error': $v.city.$error }" v-model.trim="$v.city.$model" />

								<strong class="input__message input__message--error" v-if="$v.city.$dirty && !$v.city.required">Enter your city.</strong>
							</div>
						</fieldset>

						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label' : true, 'label--error': $v.state.$error }" for="state">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									State
								</label>

								<input type="text" id="state" name="state" placeholder="State" maxlength="256" :class="{ 'input' : true, 'input--error': $v.state.$error }" v-model.trim="$v.state.$model" />

								<strong class="input__message input__message--error" v-if="$v.state.$dirty && !$v.state.required">Enter your state.</strong>
							</div>
						</fieldset>
					</div>

					<div class="flex-container">
						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label' : true, 'label--error': $v.zipCode.$error }" for="zipCode">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									ZIP or postal code
								</label>

								<input type="text" id="zipCode" name="zipCode" placeholder="ZIP or postal code" maxlength="256" :class="{ 'input' : true, 'input--error': $v.zipCode.$error }" v-model.trim="$v.zipCode.$model" />

								<strong class="input__message input__message--error" v-if="$v.zipCode.$dirty && !$v.zipCode.required">Enter your ZIP or postal code.</strong>
							</div>
						</fieldset>

						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label' : true, 'label--error': $v.country.$error }" for="country">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
									Country
								</label>

								<div class="select">
									<select
										v-model="$v.country.$model"
										id="country"
										name="country"
										:class="{
											'select__control': true,
											'select__control--error': $v.country.$error
										}">
										<option v-for="(item, index) in getCountryCodeData" :value="item.value" v-bind:key="index" v-text="item.label" />
									</select>
									<svg-icon
										icon="chevron-small-down"
										class="select__icon"
										role="presentation" />
								</div>

								<strong class="input__message input__message--error" v-if="$v.country.$dirty && !$v.country.required">Select your country.</strong>
							</div>
						</fieldset>
					</div>

					<div class="flex-container">
						<fieldset class="flex__item">
							<div class="form__row">
								<label :class="{ 'label' : true, 'label--error': $v.company.$error }" for="company">
									<abbr aria-hidden="true" class="required-indicator" title="Required">*</abbr>
										Company
									<div class="tooltip tooltip--right">
										<div class="tooltip__trigger" aria-describedby="tooltip-company" tabindex="0">
											<svg-icon icon="info" class="icon icon--info"></svg-icon>
										</div>

										<div class="tooltip__content" role="tooltip" id="tooltip-company">
											Enter NA if not applicable
										</div>
									</div>
								</label>

								<input type="text" id="company" name="company" placeholder="Company" maxlength="256" :class="{ 'input' : true, 'input--error': $v.company.$error }" v-model.trim="$v.company.$model" />

								<strong class="input__message input__message--error" v-if="$v.company.$dirty && !$v.company.required">Enter your company name.</strong>
							</div>
						</fieldset>

						<fieldset class="flex__item">
							<div class="form__row">
								<label class="label" for="language">Language</label>
								<div class="select">
									<select
										v-model="$v.language.$model"
										id="language"
										name="language"
										class="select__control">
										<option v-for="(item, index) in getLanguages" :value="item.value" v-bind:key="index" v-text="item.label" />
									</select>
									<svg-icon icon="chevron-small-down" class="select__icon" role="presentation"></svg-icon>
								</div>
							</div>
						</fieldset>
					</div>

					<fieldset class="captchaWrapper">
						<div class="form__row">
							<vue-recaptcha
								ref="recaptcha"
								recaptchaHost="www.recaptcha.net"
								:sitekey="keys.site"
								:loadRecaptchaScript="true"
								@verify="onCaptchaVerified"
								@expired="onCaptchaExpired" />

							<strong class="input__message input__message--error" style="visibility: visible" v-if="applicationState.captcha.hasError">You must complete the security checks.</strong>
						</div>
					</fieldset>

					<div class="grid-container--createAccountFooter">
						<div class="createAccount__button">
							<button type="button" class="button--createAccount button button--primary" @click="handleFormSubmit">Create account</button>
						</div>
						<div class="createAccount__text">
							<p>If you select <b>Create account</b>, you confirm that you understand and accept Anaplan&rsquo;s <a href="https://www.anaplan.com/terms-of-use" class="link">terms of service</a> and <a href="https://community.anaplan.com/kb/articles/3-anaplan-community-guidelines" class="link">Community guidelines</a>.</p>
						</div>
					</div>
				</form>

				<div class="orBar">
					<h2><span>OR</span></h2>
				</div>

				<div class="alreadyMember">
					<h1 class="t-grapefruit">Already an Anaplan Community member?</h1>

					<p><a href="https://id.anaplan.com/home/anaplancust_community_1/0oang0aew5yXZ4PXp357/alnng0jihlL76szVI357" class="link">Log in here</a></p>
				</div>
			</div>
		</div>

		<Footer />

		<div>
			<Loader v-if="applicationState.pendingResponse" />

			<div v-if="applicationState.modalOpen" class="modal">
				<aside class="modal__content">
					<header class="modal__header">
						<h2 class="modal__title">
							Success &ndash; We have received your registration.
						</h2>
						<button class="button button--icon" type="button" @click="hideModalClick">
							<span class="sr-only">Close</span>
							<svg-icon aria-hidden="true" class="button__icon icon icon--medium" icon="close"></svg-icon>
						</button>
					</header>
					<div class="modal__body modal__body--inset">
						<p>Hello {{ firstName }}, you&rsquo;re so close.</p>

						<p>To complete your registration, head to your inbox and verify your email address by following the instructions in the email. You will set the password for your account at this time.</p>
					</div>
				</aside>
			</div>
		</div>
	</div>
</template>

<script>
	import Footer from './components/Footer'

	import VueRecaptcha from 'vue-recaptcha'

	let countries = require('i18n-iso-countries')
	countries.registerLocale(require('i18n-iso-countries/langs/en.json'))

	// Anaplan Design System
		import Loader from './components/Loader'

		import iconSprite from '@anaplan/design-system-icons/images/icon-sprite.svg'
		import registerIcons from '@anaplan/design-system-icons'
		registerIcons("svg-icon", iconSprite)()

		import './assets/scss/styles.scss'

	// GraphQL Tag - used to write GraphQL queries that are parsed into standard GraphQL AST
		import gql from 'graphql-tag'

	// Vuelidate
		import { email, helpers, required, sameAs } from 'vuelidate/lib/validators'

		// Configure custom validators https://vuelidate.js.org/#sub-simplest-example
		const userNameFormatValidator = helpers.regex('userNameFormatValidator', /^[a-zA-Z0-9]*$/)

		/**
		 * Determines if the e-mail address the user entered is an Anaplan
		 * address. If it is, returns false to indicate that the address can not
		 * be used.
		 * @param {string} value The text the user entered in a field
		 * @returns {Boolean} Whether or not the entered e-mail address can be used
		 */
		const anaplanUserValidator = (value) => {
			let result = /@anaplan.com$/i.test(value)

			return !result
		}

		/**
		 * Determines if the e-mail address the user entered is valid per our
		 * own custom e-mail validation rules, applied on top of the Vuelidate
		 * `email` validator.
		 * @param {string} value The text the user entered in a field
		 * @returns {Boolean} Whether or not the entered e-mail address can be used
		 */
		const emailExtended = (value) => {
			/* Only the characters in this group are allowed. Note that capital
			letters are *not* allowed here. */
			let result = /^[a-z0-9_\-.@]*$/.test(value)

			return result
		}

		const isUniqueUserNameValidator = (value, vm) => vm.applicationState.isUniqueUserName
		const isUniqueEmailValidator = (value, vm) => vm.applicationState.isUniqueEmail

	export default {
		name: 'okta-user-ui',

		components: {
			Footer,
			Loader,
			VueRecaptcha,
		},

		data: function () {
			return {
				keys: {
					site: process.env.VUE_APP_RECAPTCHA_SITEKEY,
				},
				applicationState: {
					pendingResponse: false,
					isUniqueUserName: true,
					isUniqueEmail: true,
					modalOpen: false,
					captcha: {
						isValid: false,
						hasError: false,
						response: null,
					}
				},
				userName: '',
				firstName: '',
				lastName: '',
				email: '',
				confirmEmail: '',
				city: '',
				state: '',
				zipCode: '',
				country: '',
				company: '',
				language: '',
			}
		},

		validations: {
			userName: {
				required,
				userNameFormatValidator,
				isUniqueUserNameValidator
			},
			firstName: {
				required
			},
			lastName: {
				required
			},
			email: {
				anaplanUserValidator,
				email,
				emailExtended,
				required,
				isUniqueEmailValidator
			},
			confirmEmail: {
				email,
				emailExtended,
				required,
				sameAsEmail: sameAs('email')
			},
			city: {
				required
			},
			state: {
				required
			},
			zipCode: {
				required
			},
			country: {
				required
			},
			company: {
				required
			},
			language: {
				// An optional field with no validations
			},
		},

		computed: {
			/**
			 * Creates country code data in a format supported by the select
			 * field. Countries are sorted by country name. A default "not
			 * specified" option is also included.
			 * @see https://www.npmjs.com/package/i18n-iso-countries
			 */
			getCountryCodeData: function () {
				let out = []

				const data = countries.getNames('en', { select: 'official' })

				for (const [key, value] of Object.entries(data)) {
					out.push(
						{
							label: value,
							value: key,
						}
					)
				}

				// Sort the country codes by country name before returning them.
				out.sort(function (a, b) {
					// Ignore case in comparisons
					let labelA = a.label.toUpperCase()
					let labelB = b.label.toUpperCase()

					if (labelA < labelB) {
						return -1
					}

					if (labelA > labelB) {
						return 1
					}

					// Values are equal
					return 0
				})

				// Add a "nothing selected" option to the beginning of the list.
				out.unshift({ label: 'Select your country', value: '' })

				return out
			},

			/**
			 * Creates language data in a format supported by the select
			 * field. A default "not specified" option is also included.
			 */
			getLanguages: function () {
				return [
					{
						value: "",
						label: "Select your language"
					},
					{
						value: "en",
						label: "English"
					},
					{
						value: "jp",
						label: "日本語"
					},
					{
						value: "de",
						label: "Deutsch"
					},
					{
						value: "fr",
						label: "Français"
					},
					{
						value: "ru",
						label: "Pусский"
					},
				]
			},
		},

		methods: {
			/**
			 * Handles an event fired when the captcha has been verified.
			 * @param {string} response The user response token provided by the
			 *     reCAPTCHA client-side integration on your site.
			 */
			onCaptchaVerified: function (response) {
				this.applicationState.captcha.response = response
				this.applicationState.captcha.hasError = false
				this.applicationState.captcha.isValid = true
			},

			/**
			 * Handles an event fired when the captcha solution expires.
			 */
			onCaptchaExpired: function () {
				this.applicationState.captcha.hasError = true
				this.applicationState.captcha.isValid = false
				this.applicationState.captcha.response = null
			},

			/**
			 * Builds a custom navigation for the Gnav.
			 * @returns {Array} Navigation structure for the Gnav.
			 */
			getCustomNavigation: function () {
				return [
					{
						title: 'Community',
						href: 'https://community.anaplan.com/'
					},
					{
						title: 'Learning Center',
						href: 'https://learning.anaplan.com/'
					},
					{
						title: 'Groups',
						href: 'https://community.anaplan.com/categories/groups'
					},
					{
						title: 'Support',
						href: 'https://support.anaplan.com/'
					},
				]
			},

			/**
			 * A click event handler for the "registration successful" modal
			 * close button.
			 */
			hideModalClick: function (/* event */) {
				this.applicationState.modalOpen = false
			},

			/**
			 * A submit event handler for the registration form. Resets the
			 * error states of fields that require back-end verification before
			 * revalidating the entire form and determining if it is valid to
			 * be submitted for processing.
			 */
			handleFormSubmit: function (/* event */) {
				/* Run validation on the entire form so we can determine if
				there are any validation errors. https://vuelidate.js.org/#sub-v-methods */
				this.$v.$touch()

				// Reset form validations that are not handled by this.$v.touch()
				this.applicationState.isUniqueUserName = true
				this.applicationState.isUniqueEmail = true
				this.applicationState.captcha.hasError = !this.applicationState.captcha.isValid

				// Process the form if there were no validation errors.
				if (this.applicationState.captcha.isValid && !this.$v.$invalid) {
					this.processForm()
				}
			},

			/**
			 * Once the form has been validated on the client-side, there are
			 * several back-end validations that have to also be done before the
			 * form is fully validated and can be saved.
			 */
			processForm: function () {
				this.applicationState.pendingResponse = true

				/* This logic stack is composed of Promises from top to bottom.
				It's important to know that what you return within a then()
				callback can do different things. If you return a *value* like
				true or false or "42", the next then() is called with that value.
				If you return a *Promise*, though, then that Promise executes
				*first* and passes *its* return value to the next then().
				See https://web.dev/promises/#queuing-asynchronous-actions.
				NOTE: Use Promise.reject() to break the then() chain at any point
				and fall into the catch() callback. */

				const emailCheck = this._checkEmailAddressExists(this.email)
				const usernameCheck = this._checkUserNameExists(this.userName)

				Promise.allSettled([emailCheck, usernameCheck]).then((results) => {
					let result

					// emailCheck
					result = results[0].value
					if (result.inUse) {
						this.applicationState.isUniqueEmail = false
					}

					// usernameCheck
					result = results[1].value
					if (!result.available) {
						this.applicationState.isUniqueUserName = false
					}

					/* The return value here becomes the value passed to the
					next .then(). It should indicate whether or not to do
					the validation of the captcha token. */
					if (this.applicationState.isUniqueEmail && this.applicationState.isUniqueUserName) {
						return true
					} else {
						return Promise.reject({message: 'Uniqueness check(s) failed'})
					}
				}).then((/* result */) => {
					// console.log(`result of GQL validations: ${result}`)

					/* Remember that a captcha response can only be validated on
					the server once, and after that, the user will have to
					complete the challenge again, so this must always be the
					last thing checked after everything else on the form is valid. */
					return this._validateToken(this.applicationState.captcha.response)
				}).then((result) => {
					// console.log(`result of captcha response validation:`)
					// console.dir(result)

					if (result && result.success) {
						return this.collectAndSaveUser()
					} else {
						// console.info(`Invalid token`)

						this.applicationState.captcha.isValid = false
						this.applicationState.captcha.hasError = true

						return Promise.reject({message: 'captcha token validation failed'})
					}
				}).then(result => {
					// console.log(`result of collectAndSaveUser:`)
					// console.dir(result)

					if (result.success) {
						this.finishProcess()
					} else {
						// Handles an "email already in use" error
						for (var i = 0; i < result.errors.length; i++) {
							if (result.errors[i].includes('email is already in use')) {
								this.applicationState.isUniqueEmail = false
								break
							}
						}

						return Promise.reject({message: 'error saving user'})
					}
				}).catch((error) => {
					// TODO: Show a generic form handling error?
					console.error(error.message)
				}).finally(() => {
					// console.log(`Everything is done.`)

					this.applicationState.pendingResponse = false
				})
			},

			/**
			 * Assembles user data from the form in the shape expected by the
			 * back-end, and submits it to be saved.
			 * @return {Promise}
			 * @see /okta-user-server/src/graphql/createUser/typeDef.js
			 * @see https://apollo.vuejs.org/guide/apollo/mutations.html
			 * @see https://hasura.io/learn/graphql/vue/mutations-variables/3-create-mutation/
			 */
			collectAndSaveUser: function () {
				// console.info(`Attempting to save user...`)

				let user = {
					firstName: this.firstName,
					lastName: this.lastName,
					email: this.email,
					username: this.userName,
					company: this.company,
					city: this.city,
					state: this.state,
					countryCode: this.country,
					zipCode: this.zipCode,
					preferredLanguage: this.language,
					partner: false,
					customer: true,
				}

				return this.$apollo.mutate({
					// Query
					mutation: gql`mutation createCPXUser($user: UserCreate!) {
						createUser(user: $user) {
							firstName,
							lastName,
							email,
							username,
							company
						}
					}`,
					// Parameters
					variables: {
						user
					}
				}).then((/* data */) => {
					return {
						success: true,
						errors: []
					}
				}).catch(error => {
					return {
						success: false,
						errors: [error.message]
					}
				})
			},

			/**
			 * Executes when the user has been registered successfully. Any
			 * remaining clean-up, etc. should be done here.
			 */
			finishProcess: function () {
				this.applicationState.modalOpen = true
			},

			/**
			 * Checks to see if the specified e-mail address has already been
			 * taken by another user.
			 * @param {string} username The value to be checked against existing
			 *     usernames in the system.
			 * @return {Promise}
			 */
			_checkEmailAddressExists: function (emailAddress = '') {
				return this.$apollo.query({
					query: gql`query checkEmailAddress($emailAddress: String!) {
						checkEmailAddress(emailAddress: $emailAddress) {
							inUse
							success
						}
					}`,
					variables: {
						emailAddress
					},
					fetchPolicy: 'no-cache' // Always check network and do not cache
				}).then(result => {
					let data = result.data.checkEmailAddress

					return {
						inUse: data.inUse,
						success: data.success,
						errors: []
					}
				}).catch(error => {
					return {
						inUse: null,
						success: false,
						errors: [ error.message ]
					}
				})
			},

			/**
			 * Checks to see if the specified username has already been taken
			 * by another user.
			 * @param {string} username The value to be checked against existing
			 *     usernames in the system.
			 * @return {Promise}
			 */
			_checkUserNameExists: function (username = '') {
				return this.$apollo.query({
					query: gql`query checkUser($username: String!) {
						checkUsername(username: $username) {
							username
						}
					}`,
					variables: {
						username
					},
					fetchPolicy: 'no-cache' // Always check network and do not cache
				}).then((/* data */) => {
					// This username is available.
					return {
						available: true
					}
				}).catch(error => {
					/* If the username is taken, then a GraphQL error will have
					been raised with a specific message. */
					return {
						available: !error.message.includes('Username in use')
					}

					/* TODO: Rethrow the error if it isn't the "username in use"
					error, or create a new GraphQL query that responds to a
					username check with a boolean value, instead of an error. */
				})
			},

			/**
			 * Validates the captcha token with the captcha API to ensure it is
			 * valid. Note that you can only validate a token once, to prevent
			 * replay attacks, so this should be the very last step before the
			 * user gets created.
			 * @param {string} token The token generated by the captcha on the
			 *     client side.
			 * @return {Promise}
			 */
			_validateToken: function (token = '') {
				return this.$apollo.query({
					query: gql`query validateCaptchaToken($token: String!) {
						validateCaptchaToken(token: $token) {
							success
							fail_codes
						}
					}`,
					variables: {
						token
					},
					fetchPolicy: 'no-cache' // Always check network and do not cache
				}).then(result => {
					/* result.data.validateCaptchaToken contains information
					about the token validation, including a "success" key which
					indicates if the token is valid (true) or not (false), and a
					"fail_codes" array of strings that indicate any errors
					encountered during validation, which can be used to show
					error messages to the user. */
					// console.dir(result.data.validateCaptchaToken)

					return result.data.validateCaptchaToken
				}).catch(error => {
					// TODO: How to handle this?
					console.error(error.message)
				})
			}
		}
	}
</script>

<!-- Globally import the Gnav's CSS from the NPM package. -->
<style src="@anaplan/global-navigation/dist/global-navigation.css" />

<style lang="scss">
	/* These additional styles are not yet available from ADS, and should be
	removed once they are. */

	form.form div.select select.select__control--error {
		box-shadow: 0 0 0 1px #db3743;
	}

	form.form div.select ~ strong.input__message--error {
		visibility: visible;
	}

	.introWrapper {
		text-align: left;
		width: 100%;
		max-width: 100%;

		h1 {
			margin-bottom: 24px;
			margin-top: 22px;
		}
	}

	.alreadyMember {
		h1 {
			display: inline;
			margin-right: 16px;
		}

		p {
			display: inline;
		}
	}

	.button--createAccount {
		white-space: nowrap;
	}

	form.form fieldset.captchaWrapper {
		margin-top: 16px;
	}

	.grid-container--registration {
		.grid-item--form {
			padding: 90px;
		}
	}

	@media screen and (max-width: 979px) {
		.grid-container--registration {
			.grid-item--form {
				padding: 80px 40px;
			}
		}
	}

	@media screen and (min-width: 979px) {
		.grid-container--registration {
			max-width: 1200px;
			margin: 0 auto;

			.grid-item--form {
				padding: 120px 90px 100px 90px;
			}
		}
	}

	.orBar {
		margin: 48px 0;

		h2 {
			width: 100%;
			text-align: center;
			border-bottom: 1px solid #999;
			color: #999;
			line-height: 0.1em;
			margin: 10px 0 20px;

			span {
				background: #fff;
				padding: 0 10px;
			}
		}
	}

	.grid-container--stepBadges {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
		gap: 48px;
		margin: 16px 0;
		white-space: nowrap; /* Keeps the step header and badge content on one line at all times */

		span.badge {
			background-color: #3c67ea;
			color: white;
			border: 0;
			margin-right: 4px;
			display: inline-block;
			min-width: 16px;
			text-align: center;
		}

		h2 {
			display: inline;
			font-weight: bold;
		}

		p, ul {
			margin: 0 0 0 24px;
			font-size: smaller;
			line-height: 120%;
			white-space: normal;
		}

		ul {
			margin-left: 0;
		}
	}

	@media screen and (max-width: 720px) { /* smallest size before the steps wrap and look bad */
		.grid-container--stepBadges {
			display: grid;
			grid-template-columns: 1fr;
			gap: 16px;
		}
	}

	@media screen and (max-width: 600px) {
		.grid-container {
			display: block;
		}
	}

	.grid-container--createAccountFooter {
		display: grid;
		grid-template-columns: auto 99fr; /* Keep text adjacent to button */
		grid-template-rows: 1fr;
		gap: 16px;
		grid-template-areas: "cA_b cA_t";
		margin-top: 16px;

		.createAccount__button { grid-area: cA_b; }

		.createAccount__text { grid-area: cA_t; font-size: small; }

		.createAccount__text > p {
			margin: 0;
		}
	}

	@media screen and (max-width: 1199px) {
		.grid-container--createAccountFooter {
			display: block;

			.createAccount__text {
				margin-top: 16px;
			}
		}
	}

	.modal__body.modal__body--inset p:first-child {
		margin-top: 0;
	}
</style>