import Vue from 'vue'
import App from './App.vue'

/* This is needed only because of the use of "vue-cli-plugin-apollo": "~0.22.2"
and an error with "regeneratorruntime is not defined" in the console when trying
to *NOT* use async/await in App.js. If you downgrade to 0.21.3, this will not
be needed.
@see https://github.com/Akryum/vue-cli-plugin-apollo/issues/355#issue-638026406
@see https://www.npmjs.com/package/vue-cli-plugin-apollo */
import 'regenerator-runtime/runtime'

import { createProvider } from './vue-apollo'

// Global Navigation
	const GlobalNavigation = require('@anaplan/global-navigation')
	Vue.use(GlobalNavigation)

// Form Validation
	import Vuelidate from 'vuelidate'
	Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
	apolloProvider: createProvider(),
	render: h => h(App)
}).$mount('#app')
